var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "exception-list mt-2",
      staticStyle: { width: "100%" },
      attrs: { id: _vm.id },
    },
    [
      _c(
        "div",
        { staticClass: "exception-label" },
        [
          _c("div", { staticClass: "title mb-2" }, [
            _vm._v(_vm._s(_vm.$t("calendar.title_exception"))),
          ]),
          _c(
            "b-form-checkbox",
            {
              staticClass: "mb-2",
              on: { change: _vm.changeBaseExceptions },
              model: {
                value: _vm.baseExceptionsShow,
                callback: function ($$v) {
                  _vm.baseExceptionsShow = $$v
                },
                expression: "baseExceptionsShow",
              },
            },
            [_vm._v(_vm._s(_vm.$t("calendar.show_base_calendar_exceptions")))]
          ),
          _c(
            "div",
            { staticClass: "exception-toolbar border" },
            [
              !_vm.readOnly
                ? [
                    _vm.canAdd()
                      ? _c(
                          "span",
                          [
                            _c("b-popover", {
                              attrs: {
                                target: "BTN_ADD_" + _vm.id,
                                placement: "top",
                                triggers: "hover",
                                content: _vm.$t("button.add"),
                              },
                            }),
                            _c(
                              "b-btn",
                              {
                                attrs: { id: "BTN_ADD_" + _vm.id },
                                on: { click: _vm.exceptionAdd },
                              },
                              [
                                _c("font-awesome-icon", {
                                  style: {
                                    color: "var(--grid-toolbar-button)",
                                  },
                                  attrs: { icon: ["far", "plus"] },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "span",
                      [
                        _c("b-popover", {
                          attrs: {
                            target: "BTN_EDIT_" + _vm.id,
                            placement: "top",
                            triggers: "hover",
                            content: _vm.$t("button.edit"),
                          },
                        }),
                        _c(
                          "b-btn",
                          {
                            attrs: {
                              disabled: _vm.disableEdit,
                              id: "BTN_EDIT_" + _vm.id,
                            },
                            on: { click: _vm.exceptionEdit },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "pen-to-square"] },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.canDelete()
                      ? _c(
                          "span",
                          [
                            _c("b-popover", {
                              attrs: {
                                target: "BTN_DELETE_" + _vm.id,
                                placement: "top",
                                triggers: "hover",
                                content: _vm.$t("button.delete"),
                              },
                            }),
                            _c(
                              "b-btn",
                              {
                                attrs: {
                                  disabled: _vm.disableDelete,
                                  id: "BTN_DELETE_" + _vm.id,
                                },
                                on: { click: _vm.exceptionDelete },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "trash-can"] },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.canAdd()
                      ? _c(
                          "span",
                          [
                            _c("b-popover", {
                              attrs: {
                                target: "BTN_IMPORT_" + _vm.id,
                                placement: "top",
                                triggers: "hover",
                                content: _vm.$t("button.import"),
                              },
                            }),
                            _c(
                              "b-dropdown",
                              {
                                attrs: {
                                  id: "ddown-offset",
                                  offset: "25",
                                  "no-caret": "",
                                },
                              },
                              [
                                _c("template", { slot: "button-content" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text",
                                      attrs: { id: "BTN_IMPORT_" + _vm.id },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "inbox-in"] },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "dropdown-item",
                                      on: {
                                        click: function ($event) {
                                          _vm.holidayShow = true
                                        },
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("calendar.import_lookup")
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "dropdown-item",
                                      on: {
                                        click: function ($event) {
                                          _vm.docImportShow = true
                                        },
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("calendar.import_from_file")
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
              _c(
                "span",
                [
                  _c("b-popover", {
                    attrs: {
                      target: "BTN_EXPORT_" + _vm.id,
                      placement: "top",
                      triggers: "hover",
                      content: _vm.$t("button.export"),
                    },
                  }),
                  _c(
                    "b-btn",
                    {
                      attrs: { id: "BTN_EXPORT_" + _vm.id },
                      on: { click: _vm.fileExport },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "inbox-out"] },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("ag-grid-vue", {
        staticClass: "ag-theme-balham exception-grid-height",
        staticStyle: { width: "100%" },
        attrs: {
          id: "exceptionGrid",
          gridOptions: _vm.gridOptions,
          columnDefs: _vm.columnDefs,
          context: _vm.context,
          defaultColDef: _vm.defaultColDef,
          overlayNoRowsTemplate: _vm.overlayNoRowsTemplate,
          rowData: _vm.rowData,
          rowSelection: _vm.multiple ? "multiple" : "single",
          rowMultiSelectWithClick: "",
          sideBar: false,
          suppressDragLeaveHidesColumns: "",
          suppressCellFocus: "",
          suppressContextMenu: "",
          suppressMultiSort: "",
        },
        on: { "grid-ready": _vm.onGridReady },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("calendar.confirmation.title_delete"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteShow,
            callback: function ($$v) {
              _vm.confirmDeleteShow = $$v
            },
            expression: "confirmDeleteShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "calendar.confirmation.exception_delete" +
                      (_vm.selected.length > 1 ? "_plural" : "")
                  )
                ) +
                " "
            ),
          ]),
        ]
      ),
      _c("ExceptionModal", {
        attrs: {
          event: _vm.event,
          readOnly:
            _vm.readOnly ||
            _vm.eventReadOnly ||
            _vm.event.uuId == null ||
            (_vm.event.uuId.includes("EXCEPTION") && !_vm.canAdd()) ||
            (!_vm.event.uuId.includes("EXCEPTION") && !_vm.canEdit()),
          show: _vm.exceptionShow,
          title: _vm.exceptionTitle,
          baseStartHour: _vm.baseStartHour,
          baseEndHour: _vm.baseEndHour,
        },
        on: {
          "update:show": function ($event) {
            _vm.exceptionShow = $event
          },
          success: _vm.exceptionSuccess,
        },
      }),
      _c("HolidaySelectorModal", {
        attrs: { show: _vm.holidayShow },
        on: {
          "update:show": function ($event) {
            _vm.holidayShow = $event
          },
          success: _vm.holidaySuccess,
        },
      }),
      _c("GanttImportDialog", {
        attrs: {
          properties: [
            { value: "name", text: _vm.$t("calendar.field.name") },
            { value: "startDate", text: _vm.$t("calendar.field.startDate") },
            { value: "endDate", text: _vm.$t("calendar.field.endDate") },
            { value: "startHour", text: _vm.$t("calendar.field.startHour") },
            { value: "endHour", text: _vm.$t("calendar.field.endHour") },
            { value: "identifier", text: _vm.$t("field.identifier") },
            { value: "working", text: _vm.$t("calendar.field.working") },
          ],
          mode: "CALENDAR",
          show: _vm.docImportShow,
          title: _vm.$t("vacation.button.import_document"),
        },
        on: {
          "modal-ok": _vm.docImportOk,
          "modal-cancel": _vm.docImportCancel,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }